<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="card-tiny-line-stats" body-class="pb-50">
          <h6>التصنيفات</h6>
          <h2 class="font-weight-bolder mb-1">
            {{ categoriesCount }}
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsProfit.chartOptions"
            :series="statisticsProfit.series"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card class="earnings-card">
          <b-row>
            <b-col cols="6">
              <b-card-title class="mb-1"> النشاطات </b-card-title>

              <b-card-text class="text-muted font-small-2 mutedActivity">
                <span>نشاطات هذا الشهر </span>
                <span class="font-weight-bolder">{{ monthlyActivitiesCount }}</span>
              </b-card-text>
            </b-col>
            <b-col cols="6" class="stats">
              <h2 class="mb-1 font-weight-bolder">
                {{ activitiesCount }}
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          color="warning"
          statistic-title="الخدمات"
          :statistic="servicesCount"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row>
    <b-card>
      <vue-apex-charts
        type="bar"
        height="200"
        :options="salesBar.chartOptions"
        :series="salesBar.series"
      />
      <hr />
    </b-card>
  </div>
</template>
<style scoped>
.stats {
  height: 134px;
  align-items: center;
  place-content: center;
  display: flex;
}
.mutedActivity {
  position: absolute;
  bottom: 0;
}
</style>
<script>
import { kFormatter } from "@core/utils/filter";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
const $trackBgColor = "#EBEBEB";
const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
  components: {
    VueApexCharts,
    StatisticCardWithAreaChart,
  },
  data() {
    return {
      earningsChart: {
        series: [53, 16, 31],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ["النشاطات", "Service", "Product"],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`;
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "activities",
                    formatter() {
                      return this.activitiesCount;
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      statisticsProfit: {
        series: [
          {
            data: [0, 20, 5, 30, 15, 45],
          },
        ],
        chartOptions: {
          chart: {
            type: "line",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: "#ffffff",
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: "circle",
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: "0px",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      data: {
        ordersRecevied: {
          series: [
            {
              name: "Orders",
              data: [10, 15, 8, 15, 7, 12, 8],
            },
          ],
          analyticsData: {
            orders: 38400,
          },
        },
      },
      avgData: {},
      salesBar: {
        series: [
          {
            name: "Sessions",
            data: [75, 125, 225, 175, 125, 75, 25],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: "none",
            },
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: "numeric",
          },
        },
      },
    };
  },
  created() {
    this.getHomeStats();
  },
  methods: {
    kFormatter,
    ...mapActions(["getHomeStats"]),
  },
  computed: mapState({
    categoriesShopsCount: (state) => state.home.categoriesShopsCount,
    categoriesCount: (state) => state.home.categoriesCount,
    activitiesCount: (state) => state.home.activitiesCount,
    monthlyActivitiesCount: (state) => state.home.monthlyActivitiesCount,
    servicesCount: (state) => state.home.servicesCount,
  }),
};
</script>
